import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Button,
  Table,
  ButtonGroup,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { startOfWeek, isWithinInterval, parseISO } from "date-fns";
import "./Historial.css"; // Asegúrate de tener este archivo CSS para los estilos
import api from "../../services/axiosConfig";
import { isMobile } from 'react-device-detect';


import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


// Registrar el idioma español y personalizar los meses
const esCustom = {
  ...es,
  localize: {
    ...es.localize,
    month: (n) => {
      const months = [
        'ENERO',
        'FEBRERO',
        'MARZO',
        'ABRIL',
        'MAYO',
        'JUNIO',
        'JULIO',
        'AGOSTO',
        'SEPTIEMBRE',
        'OCTUBRE',
        'NOVIEMBRE',
        'DICIEMBRE'
      ];
      return months[n];
    }
  }
};

registerLocale('es-custom', esCustom);
const Historial = () => {

  
  const [selectedOption, setSelectedOption] = useState("Aperturas");
  const [comision, setComision] = useState(0);
  const [aperturasSemanales, setAperturasSemanales] = useState(0);
  const [pendienteRecargas, setPendienteRecargas] = useState(0);
  const [pendienteDepositar, setPendienteDepositar] = useState(0);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState(new Date());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    fetchSummaryData(startDate, endDate);
    fetchData(selectedOption);
  }, [selectedOption, startDate, endDate]);

  useEffect(() => {
    applyDateFilter();
  }, [data, startDate, endDate]);

  const handleKeyDown = (e) => {
    const validKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "/"];
    const isNumber = /^[0-9]$/.test(e.key);

    // Si la tecla presionada no es un número, una tecla de navegación válida o "/"
    if (!isNumber && !validKeys.includes(e.key)) {
      e.preventDefault(); // Prevenir la entrada de cualquier otro carácter
    }

    if (isMobile) {
      e.preventDefault(); // Previene que se abra el teclado en móviles
    }
  };

  
  // Componente personalizado para el header del calendario
  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
    decreaseYear,
    increaseYear,
  }) => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 10; year <= currentYear; year++) {
      years.push(year);
    }

    const months = [
      'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
      'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
    ];

    return (
      <div className="react-datepicker__header-custom" style={{ padding: '10px' }}>
        <button
          className="react-datepicker__navigation--previous"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
        >
          {'<'}
        </button>
        <div style={{ display: 'inline-flex', gap: '8px' }}>
          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button
          className="react-datepicker__navigation--next"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
        >
          {'>'}
        </button>
      </div>
    );
  };
  

  const fetchSummaryData = async (startDate = null, endDate = null) => {
    const token = localStorage.getItem("token");
    try {
      // Construir la URL con las fechas si están definidas
      let url = "https://www.recargacreditos.com.mx/api/admin/comision-semanal";
      if (startDate || endDate) {
        const params = new URLSearchParams();
        if (startDate) params.append("fechaInicio", startDate.toISOString());
        if (endDate) params.append("fechaFin", endDate.toISOString());
        url += `?${params.toString()}`;
      }

      // Realizar la solicitud
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { comision, cantidadTiendas, valor_recarga, valor_adeudado } =
        response.data;

      // Actualizar el estado con los datos de respuesta
      setComision(parseFloat(comision).toFixed(2));
      setAperturasSemanales(cantidadTiendas);
      setPendienteRecargas(valor_recarga);
      setPendienteDepositar(valor_adeudado);
    } catch (error) {
      console.error("Error al obtener los datos de resumen:", error);
      if (error.response && error.response.status === 401) {
        alert("Sesión expirada. Por favor, inicie sesión nuevamente.");
        window.location.href = "/login";
      }
    }
  };

  const fetchData = async (option) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://www.recargacreditos.com.mx/api/admin/historial/${option.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setData(sortedData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const applyDateFilter = () => {
    const adjustedEndDate = endDate ? new Date(endDate.setHours(23, 59, 59, 999)) : null;

    const filtered = data.filter((item) => {
      let dateToCheck = null;

      if (selectedOption.toLowerCase() === "saldos") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "depositos") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "ventas") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "recargas") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else {
        dateToCheck = item.createdAt ? parseISO(item.createdAt) : null;
      }

      if (dateToCheck) {
        
        if (startDate && adjustedEndDate) {
          return (
            dateToCheck >= startDate && dateToCheck <= adjustedEndDate
          );
        
        } else {
          // Incluir todos los datos si no hay filtro de fecha
          return true;
        }
      }
      return false; // Excluir elementos sin fecha
    });
    setFilteredData(filtered);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      let aValue = getNestedValue(a, key);
      let bValue = getNestedValue(b, key);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredData(sorted);
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : null), obj);
  };

  const renderTableColumns = () => {
    switch (selectedOption.toLowerCase()) {
      case "aperturas":
        return (
          <>
            <th onClick={() => handleSort("usuario.nombre_tienda")}>Nombre</th>
            <th onClick={() => handleSort("createdAt")}>Fecha Creación</th>
            <th onClick={() => handleSort("updatedAt")}>
              Fecha De Eliminación
            </th>
            <th onClick={() => handleSort("usuario.celular")}>Celular</th>
            <th onClick={() => handleSort("ubicacion")}>Ubicación</th>
            <th onClick={() => handleSort("promedioSemanal")}>
              Promedio Semanal
            </th>
          </>
        );
      case "saldos":
        return (
          <>
            <th onClick={() => handleSort("tienda.usuario.nombre_tienda")}>
              Tienda
            </th>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Cantidad</th>
          </>
        );
      case "depositos":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Cantidad</th>
            <th onClick={() => handleSort("tipo")}>Tipo</th>
          </>
        );
      case "ventas":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("folio")}>Folio</th>
            <th onClick={() => handleSort("celular")}>Número</th>
            <th onClick={() => handleSort("valor")}>Cantidad</th>
            <th onClick={() => handleSort("operadora")}>Compañía</th>
            <th onClick={() => handleSort("clase")}>Clase</th>
            <th onClick={() => handleSort("vendedor")}>Tienda</th>
          </>
        );
      case "recargas":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Cantidad</th>
            <th onClick={() => handleSort("tipoMovimiento")}>Tipo</th>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderTableRows = () => {
    return filteredData.map((item, index) => (
      <tr
        key={index}
        style={{
          backgroundColor: item.usuario?.eliminado ? "#ffcccc" : "transparent",
        }}
      >
        {selectedOption.toLowerCase() === "aperturas" && (
          <>
            <td>{item.usuario?.nombre_tienda}</td>
            <td>
              {item.createdAt
                ? new Date(item.createdAt).toLocaleString()
                : "N/A"}
            </td>
            <td>
              {item.usuario?.eliminado
                ? item.updatedAt
                  ? new Date(item.updatedAt).toLocaleString()
                  : "N/A"
                : ""}
            </td>
            <td>{item.usuario?.celular}</td>
            <td>
              <a
                href={`https://www.google.com/maps?q=${item.latitud},${item.longitud}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${item.latitud}, ${item.longitud}`}
              </a>
            </td>
            <td>{item.promedioSemanal}</td>
          </>
        )}

        {selectedOption.toLowerCase() === "saldos" && (
          <>
            <td>{item.tienda?.usuario?.nombre_tienda || "N/A"}</td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td style={{ color: item.valor < 0 ? "lightcoral" : "black" }}>
              {item.valor}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "depositos" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.valor}</td>
            <td style={{ color: item.tipo === "Deposito" ? "green" : "black" }}>
              {item.tipo === "Deposito"
                ? "Depósito"
                : `Recibido - ${item.tienda?.usuario?.nombre_tienda || "N/A"}`}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "ventas" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.folio || "N/A"}</td>
            <td>{item.celular || "N/A"}</td>
            <td>{item.valor}</td>
            <td>{item.operadora || "N/A"}</td>
            <td>
              {item.tipo
                ? item.tipo.charAt(0).toUpperCase() +
                  item.tipo.slice(1).toLowerCase()
                : "N/A"}
            </td>
            <td>
              {item.Tienda?.usuario?.nombres_apellidos ||
                item.Tienda?.usuario?.nombre_tienda ||
                item.Tienda?.usuario?.correo ||
                "N/A"}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "recargas" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.valor}</td>
            <td>
              {item.tipoMovimiento}
              {item.tipoMovimiento === "Recarga" && item.celular && (
                <div style={{ fontSize: "smaller", color: "gray" }}>
                  {`Celular: ${item.celular}`}
                  {` Operadora: ${item.operadora}`}
                  <br />

                  {`Tipo: ${item.tipoRecarga
                    .charAt(0)
                    .toUpperCase()}${item.tipoRecarga.slice(1)}`}
                  {` Folio: ${item.folio}`}
                </div>
              )}
            </td>
          </>
        )}
      </tr>
    ));
  };

  const renderMobileRows = () => {
    return filteredData.map((item, index) => {
      if (selectedOption.toLowerCase() === "aperturas") {
        return (
          <div
            key={index}
            className={`mobile-card ${
              item.usuario?.eliminado ? "deleted-row" : ""
            }`}
          >
            <div className="mobile-row">
              <div>
                <strong>Nombre:</strong> {item.usuario?.nombre_tienda}
              </div>
              <div>
                <strong>Fecha Creación:</strong>{" "}
                {item.createdAt
                  ? new Date(item.createdAt).toLocaleString()
                  : "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Ubicación:</strong>{" "}
                <a
                  href={`https://www.google.com/maps?q=${item.latitud},${item.longitud}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${item.latitud}, ${item.longitud}`}
                </a>
              </div>
              <div>
                <strong>Promedio Semanal:</strong> {item.promedioSemanal}
              </div>
            </div>
            <div className="mobile-row">
              {item.usuario?.eliminado && (
                <div>
                  <strong>Fecha De Eliminación:</strong>{" "}
                  {item.updatedAt
                    ? new Date(item.updatedAt).toLocaleString()
                    : "N/A"}
                </div>
              )}
            </div>
          </div>
        );
      } else if (selectedOption.toLowerCase() === "saldos") {
        return (
          <div key={index} className="mobile-card">
            <div className="mobile-row">
              <div>
                <strong>Tienda:</strong>{" "}
                {item.tienda?.usuario?.nombre_tienda || "N/A"}
              </div>
              <div>
                <strong>Fecha:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Hora:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
              </div>
              <div>
                <strong>Cantidad:</strong>
                <div style={{ color: item.valor < 0 ? "lightcoral" : "black" }}>
                  {item.valor}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (selectedOption.toLowerCase() === "depositos") {
        return (
          <div key={index} className="mobile-card">
            <div className="mobile-row">
              <div>
                <strong>Fecha:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
              </div>
              <div>
                <strong>Hora:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Cantidad:</strong> {item.valor}
              </div>
              <div>
                <strong>Tipo:</strong>{" "}
                <div
                  style={{
                    color: item.tipo === "Deposito" ? "green" : "black",
                  }}
                >
                  {item.tipo === "Deposito"
                    ? "Depósito"
                    : `Recibido - ${
                        item.tienda?.usuario?.nombre_tienda || "N/A"
                      }`}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (selectedOption.toLowerCase() === "ventas") {
        return (
          <div key={index} className="mobile-card">
            <div className="mobile-row">
              <div>
                <strong>Fecha:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
                <br />
                {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
              </div>
              <div>
                <strong>Folio:</strong> {item.folio || "N/A"}
              </div>
              <div>
                <strong>Número:</strong> {item.celular || "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Cantidad:</strong> {item.valor}
              </div>
              <div>
                <strong>Compañía:</strong> {item.operadora || "N/A"}
              </div>
              <div>
                <strong>Clase:</strong>{" "}
                {item.tipo
                  ? item.tipo.charAt(0).toUpperCase() +
                    item.tipo.slice(1).toLowerCase()
                  : "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Tienda:</strong>{" "}
                {item.Tienda?.usuario?.nombres_apellidos ||
                  item.Tienda?.usuario?.nombre_tienda ||
                  item.Tienda?.usuario?.correo ||
                  "N/A"}
              </div>
            </div>
          </div>
        );
      } else if (selectedOption.toLowerCase() === "recargas") {
        return (
          <div key={index} className="mobile-card">
            <div className="mobile-row">
              <div>
                <strong>Fecha:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
              </div>
              <div>
                <strong>Hora:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
              </div>
            </div>
            <div className="mobile-row">
              <div>
                <strong>Valor:</strong> {item.valor}
              </div>
              <div>
                <strong>Movimiento:</strong> {item.tipoMovimiento}
              </div>
            </div>
            {item.tipoMovimiento === "Recarga" && item.celular && (
              <div className="mobile-row">
                <div>
                  <strong>Celular:</strong> {item.celular}
                </div>
                <div>
                  <strong>Operadora:</strong> {item.operadora}
                </div>
              </div>
            )}
            {item.tipoMovimiento === "Recarga" && item.celular && (
              <div className="mobile-row">
                <div>
                  <strong>Tipo:</strong>{" "}
                  {item.tipoRecarga.charAt(0).toUpperCase()}
                  {item.tipoRecarga.slice(1)}
                </div>
                <div>
                  <strong>Folio:</strong> {item.folio}
                </div>
              </div>
            )}
          </div>
        );
      }
      return null;
    });
  };

  // Definición de columnas dinámicas para la versión móvil
  const mobileSortOptions = {
    aperturas: [
      { label: "Nombre", value: "usuario.nombre_tienda" },
      { label: "Fecha Creación", value: "createdAt" },
      { label: "Promedio", value: "promedioSemanal" },
    ],
    saldos: [
      { label: "Tienda", value: "tienda.usuario.nombre_tienda" },
      { label: "Fecha", value: "fecha" },
      { label: "Cantidad", value: "valor" },
    ],
    depositos: [
      { label: "Fecha", value: "fecha" },
      { label: "Cantidad", value: "valor" },
      { label: "Tienda", value: "tienda.usuario.nombre_tienda" },
    ],
    ventas: [
      { label: "Fecha", value: "fecha" },
      { label: "Cantidad", value: "valor" },
      { label: "Compañia", value: "operadora" },
    ],
    recargas: [
      { label: "Fecha", value: "fecha" },
      { label: "Cantidad", value: "valor" },
      { label: "Tipo", value: "tipoMovimiento" },
    ],
  };

  const sortOptions = mobileSortOptions[selectedOption.toLowerCase()] || [];

  const handleStartDateChange = (date) => {
     // Cierra el calendario y previene la interacción del teclado
  setTimeout(() => {
    // Quitar el foco de manera más agresiva
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    
    // Cerrar el calendario simulando un toque fuera
    const touchEvent = new TouchEvent('touchstart', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.body.dispatchEvent(touchEvent);
    
    // También disparar evento de mouse para compatibilidad con escritorio
    const mouseEvent = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.body.dispatchEvent(mouseEvent);
  }, 1);

    if (date === null) {
      // Si se borra, establece la fecha predeterminada
      setStartDate(new Date("2024-10-01"));
    } else {
      setStartDate(date);
    }
    

       
  };

  const handleEndDateChange = (date) => {
      // Cierra el calendario y previene la interacción del teclado
  setTimeout(() => {
    // Quitar el foco de manera más agresiva
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    
    // Cerrar el calendario simulando un toque fuera
    const touchEvent = new TouchEvent('touchstart', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.body.dispatchEvent(touchEvent);
    
    // También disparar evento de mouse para compatibilidad con escritorio
    const mouseEvent = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.body.dispatchEvent(mouseEvent);
  }, 1);
    if (date === null) {
      // Si se borra, establece la fecha predeterminada
      setEndDate(new Date());
    } else {
      setEndDate(date);
    }
    

    };
const exportToExcel = async () => {
    // Capturar el estado actual al inicio de la función
    const selectedOptionSnapshot = selectedOption;
    const startDateSnapshot = startDate;
    const endDateSnapshot = endDate;
  
    // Obtener la clave interna y el título basado en el mapeo
    const optionMap = {
      Aperturas: { internalOption: "aperturas", title: "Aperturas" },
      Saldos: { internalOption: "saldos", title: "Saldos" },
      Depositos: { internalOption: "depositos", title: "Recibido y Depósitos" },
      Ventas: { internalOption: "ventas", title: "Ventas" },
      Recargas: { internalOption: "recargas", title: "Recargas Personales" },
    };
    const selectedOptionData = optionMap[selectedOptionSnapshot];
    const internalOption = selectedOptionData?.internalOption || "";
    const title = selectedOptionData?.title || selectedOptionSnapshot;
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Historial");
  
    // Determinar las columnas basadas en la clave interna
    const columns = (() => {
      switch (internalOption) {
        case "aperturas":
          return [
            { header: "Nombre", key: "nombre", width: 25 },
            { header: "Fecha Creación", key: "createdAt", width: 20 },
            { header: "Fecha De Eliminación", key: "updatedAt", width: 20 },
            { header: "Celular", key: "celular", width: 15 },
            { header: "Ubicación", key: "ubicacion", width: 30 },
            { header: "Promedio Semanal", key: "promedioSemanal", width: 20 },
          ];
        case "saldos":
          return [
            { header: "Tienda", key: "tienda", width: 25 },
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Cantidad", key: "valor", width: 15 },
          ];
        case "depositos":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Cantidad", key: "valor", width: 15 },
            { header: "Tipo", key: "tipo", width: 30 },
          ];
        case "ventas":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Folio", key: "folio", width: 15 },
            { header: "Número", key: "numero", width: 20 },
            { header: "Cantidad", key: "cantidad", width: 15 },
            { header: "Compañía", key: "compania", width: 20 },
            { header: "Clase", key: "clase", width: 15 },
            { header: "Vendedor", key: "vendedor", width: 25 },
          ];
        case "recargas":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Cantidad", key: "valor", width: 15 },
            { header: "Tipo", key: "tipo", width: 15 },
            { header: "Celular", key: "celular", width: 15 },
            { header: "Operadora", key: "operadora", width: 15 },
            { header: "Tipo recarga", key: "tipor", width: 15 },
            { header: "Folio", key: "folio", width: 15 },
          ];
        default:
          return [];
      }
    })();
  
    // Número de columnas
    const columnCount = columns.length;
  
    // Agregar encabezado con título y fechas
    worksheet.mergeCells(1, 1, 1, columnCount);
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").font = { size: 16, bold: true };
    worksheet.getCell("A1").alignment = { horizontal: "center" };
  
    // Establecer fechas
    worksheet.mergeCells(2, 1, 2, columnCount);
    worksheet.getCell(
      "A2"
    ).value = `Fecha de Inicio: ${startDateSnapshot.toLocaleDateString()}  |  Fecha de Corte: ${endDateSnapshot.toLocaleDateString()}`;
    worksheet.getCell("A2").font = { size: 12 };
    worksheet.getCell("A2").alignment = { horizontal: "center" };
  
    // Eliminar 'header' de las columnas al configurar worksheet.columns
    const columnsWithoutHeader = columns.map(({ header, ...rest }) => rest);
    worksheet.columns = columnsWithoutHeader;
  
    // Estilo de fondo celeste y letras blancas para los encabezados de columna (fila 3)
    worksheet.getRow(3).font = { bold: true, color: { argb: "FFFFFFFF" } };
    worksheet.getRow(3).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "00aae4" } };
  
    // Agregar los encabezados de columna manualmente con filtro automático
    columns.forEach((column, index) => {
      const cell = worksheet.getCell(3, index + 1);
      cell.value = column.header;
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // Blanco y negrita
      cell.alignment = { horizontal: "center" };
    });
  
    worksheet.autoFilter = { from: "A3", to: `${String.fromCharCode(64 + columnCount)}3` }; // Aplicar filtro automático
  
    // Iniciar desde la fila 4 para dejar espacio al encabezado
    const startingRow = 4;
  
    // Crear un formateador de moneda
    const currencyFormatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    });
  
    // Agregar datos
    filteredData.forEach((item, dataIndex) => {
      let rowData = {};
  
      switch (internalOption) {
        case "aperturas":
          rowData = {
            nombre: item.usuario?.nombre_tienda || "N/A",
            createdAt: item.createdAt ? new Date(item.createdAt).toLocaleString() : "N/A",
            updatedAt: item.usuario?.eliminado ? item.updatedAt ? new Date(item.updatedAt).toLocaleString() : "N/A" : "",
            celular: item.usuario?.celular.slice(-10) || "N/A",
            ubicacion: item.latitud && item.longitud ? `${item.latitud}, ${item.longitud}` : "N/A",
            promedioSemanal: item.promedioSemanal || "N/A",
          };
          break;
        case "saldos":
          rowData = {
            tienda: item.tienda?.usuario?.nombre_tienda || "N/A",
            fecha: item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: item.valor,
          };
          break;
        case "depositos":
          rowData = {
            fecha: item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: item.valor,
            tipo: item.tipo === "Deposito" ? "Depósito" : `Recibido - ${item.tienda?.usuario?.nombre_tienda || "N/A"}`,
          };
          break;
        case "ventas":
          rowData = {
            fecha: item.fecha ? new Date(item.fecha).toLocaleDateString() : "",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "",
            folio: item.folio || "",
            numero: item.celular || "",
            cantidad: item.valor,
            compania: item.operadora || "",
            clase: item.tipo ? item.tipo.charAt(0).toUpperCase() + item.tipo.slice(1).toLowerCase() : "N/A",
            vendedor: item.Tienda?.usuario?.nombres_apellidos || item.Tienda?.usuario?.nombre_tienda || item.Tienda?.usuario?.correo || "N/A",
          };
          break;
        case "recargas":
          rowData = {
            fecha: item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: item.valor,
            tipo: item.tipoMovimiento,
            celular: item?.celular || "",
            operadora: item?.operadora || "",
            tipor: item?.tipoRecarga ? item.tipoRecarga.charAt(0).toUpperCase() + item.tipoRecarga.slice(1) : "",
            folio: item?.folio || "",
          };
          break;
        default:
          break;
      }
  
      // Agregar la fila de datos
      const rowIndex = startingRow + dataIndex;
      worksheet.addRow(rowData);
  
      // Aplicar formato a las celdas si es necesario
      const row = worksheet.getRow(rowIndex);
      row.eachCell((cell) => {
        cell.font = { color: { argb: "FF000000" } }; // Negro
      });
    });
  
    // Ajustar el ancho de las columnas si es necesario
    worksheet.columns.forEach((column) => {
      column.width = column.width < 20 ? 20 : column.width;
    });
  
    // Guardar el workbook
    const filename = `${title}.xlsx`;
    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), filename);
  };
  
  return (
    <Container>
      <br />
      <Row>
        <Col md={12} className="d-flex justify-content-between mb-3">
          <h1 className="text-left" style={{ color: "#0A74DA" }}>
            Historial
          </h1>
          <Button variant="success" onClick={exportToExcel}>
            Descargar
          </Button>
        </Col>
      </Row>

      <Row className="mb-1">
        <Col md={3}>
          <div>
            <strong>Comisión:</strong> ${comision}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Aperturas semanales:</strong> {aperturasSemanales}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Pendiente de recargas:</strong> ${pendienteRecargas}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Pendiente de depositar:</strong> ${pendienteDepositar}
          </div>
        </Col>
      </Row>
      <Row className="mb-4 align-items-center">
        <Col xs={12} md={6} className="mb-3">
          <DropdownButton
            title={selectedOption}
            onSelect={(e) => setSelectedOption(e)}
            variant="outline-secondary"
            className="w-100"
          >
            <Dropdown.Item eventKey="Aperturas">Aperturas</Dropdown.Item>
            <Dropdown.Item eventKey="Saldos">Saldos</Dropdown.Item>
            <Dropdown.Item eventKey="Depositos">
              Recibido y depósitos
            </Dropdown.Item>
            <Dropdown.Item eventKey="Ventas">Ventas</Dropdown.Item>
            <Dropdown.Item eventKey="Recargas">
              Recargas personales
            </Dropdown.Item>
          </DropdownButton>
        </Col>

        <Col xs={6} md={3} className="mb-2 ml-md-auto">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              handleStartDateChange(date);
              // Inmediatamente quitar el foco después de seleccionar
              document.activeElement.blur();
            }}
            placeholderText="Fecha de inicio"
            dateFormat="dd/MM/yyyy"
            maxDate={endDate || new Date()}
            className="form-control w-100"
            onKeyDown={handleKeyDown}
            popperPlacement="bottom-start"
            portalId="root-portal"
            inputMode="none" // Desactiva el teclado en dispositivos móviles
            //onFocus={(e) => e.target.blur()} // Evita el teclado pero permite abrir el calendario
            
            locale="es-custom"
            renderCustomHeader={CustomHeader}


          calendarStartDay={1} 

             // Propiedades para prevenir edición
          //  readOnly={true}
          onFocus={(e) => {
            e.target.blur(); // Previene el teclado
          }}
          onClick={(e) => {
            e.target.focus(); // Permite abrir el calendario
          }}
          />
        </Col>
        <Col xs={6} md={3} className="mb-2">
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              handleEndDateChange(date);
              // Inmediatamente quitar el foco después de seleccionar
              document.activeElement.blur();
            }}           
             placeholderText="Fecha de fin"
            dateFormat="dd/MM/yyyy"
            minDate={startDate}
            maxDate={new Date()}
            className="form-control w-100"
            onKeyDown={handleKeyDown}
            inputReadOnly
            popperPlacement="bottom-end"
            portalId="root-portal"
            inputMode="none" // Desactiva el teclado en dispositivos móviles
            //onFocus={(e) => e.target.blur()} // Evita el teclado pero permite abrir el calendario
          
            locale="es-custom"
            renderCustomHeader={CustomHeader}

            calendarStartDay={1} 
               // Propiedades para prevenir edición
   // readOnly={true}
   onFocus={(e) => {
    e.target.blur(); // Previene el teclado
  }}
  onClick={(e) => {
    e.target.focus(); // Permite abrir el calendario
  }}
         />
        </Col>
      </Row>

      <Row className="my-1 d-block d-md-none">
        <Col className="d-flex justify-content-center">Ordenar por:</Col>
        <Col className="d-flex justify-content-center">
          <ButtonGroup className="mb-2">
            {sortOptions.map((option, index) => (
              <Button
                key={index}
                variant="outline-secondary"
                onClick={() => handleSort(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {/* Versión de escritorio */}
          <div className="d-none d-md-block">
            <Table hover className="custom-table">
              <thead>
                <tr>{renderTableColumns()}</tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </Table>
          </div>

          {/* Versión móvil */}
          <div className="d-md-none mobile-cards">{renderMobileRows()}</div>
        </Col>
      </Row>

      <style>{`
        .custom-table {
          border-collapse: collapse;
          width: 100%;
          min-height: 150px;
        }
        .custom-table thead th {
          border-bottom: 1px solid #ddd;
        }
        .custom-table tbody tr {
          transition: background-color 0.3s ease;
        }
        .custom-table tbody tr:hover {
          background-color: #f1f1f1;
        }
        .custom-table tbody tr:nth-child(odd) {
          background-color: #f9f9f9;
        }
        .custom-table tbody tr:nth-child(even) {
          background-color: #ffffff;
        }
        .custom-table td,
        .custom-table th {
          border: none;
          padding: 12px 15px;
        }
        .mobile-cards {
          display: flex;
          flex-direction: column;
          gap: 0;
          min-height: 200px;

        }
        .mobile-card {
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          background-color: #fff;
          transition: background-color 0.3s ease;
        }
        .deleted-row {
          background-color: #ffcccc;
        }
        .mobile-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 5px 0;
        }
        .mobile-row > div {
          flex: 1 1 30%;
          margin-bottom: 5px;
        }
        .mobile-row strong {
          display: block;
        }
        @media (min-width: 768px) {
          .mobile-cards {
            display: none;
          }
        }
      `}</style>
    </Container>
  );
};

export default Historial;
